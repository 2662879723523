:root {
    --color-darkest: #000000;
    --color-dark: #1c1c1c;
    --color-mid: #6d6d6d;
    --color-light: #999999;
    --color-lightest: #ffffff;
    --color-muted: #757575;

    --background-color: var(--color-darkest);
    --text-color-primary: var(--color-lightest);
    --text-color-secondary: var(--color-light);
    --text-color-muted: var(--color-muted);
    --link-color: var(--color-mid);
    --footer-background-color: var(--color-darkest);
    --footer-text-color: var(--color-muted);
}

* {
    font-family: 'helvetica', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    color: var(--text-color-primary);
    position: relative;
    min-height: 100vh;
    background-color: #141412!important;
}

p {
    font-size: 14px;
    line-height: 1.8;
    margin: 0;
    color: var(--text-color-secondary);
    white-space: pre-line;
}

#root {
    min-height: 100vh;
}

h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

h2 {
    font-weight: 600;
    font-size: 14px;
    color: var(--text-color-secondary);
    text-transform: uppercase;
    margin-bottom: 5px;
}

strong {
    font-weight: 600;
}

.nav {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 10px;
}

.nav>div {
    padding: 0 10px;
}

.nav>div a {
    text-decoration: none;
    color: var(--link-color);
    font-size: 14px;
    font-weight: 600;
}

.nav>div:first-of-type {
    position: absolute;
    left: 10px;
}

#nav0 a {
    color: var(--link-color) !important;
}

.active {
    color: var(--text-color-primary) !important;
}

.cv {
    display: flex;
    padding: 20px 20px 90px 20px;
}

.cvRow {
    display: flex;
}

.cvRow>p {
    padding-right: 10px;
}

.personalInfo img {
    width: 100%;
    padding-bottom: 20px;
}

.personalInfo>p:first-of-type {
    margin-bottom: 40px;
}

.cvLeftCol {
    padding-right: 10px;
    width: 225px;
}

.cvRightCol {
    width: 74%;
    padding-left: 45px;
}

.cvDate {
    margin-right: 30px;
    min-width: 75px;
    display: flex;
    justify-content: flex-end;
}

.cvEntry,
.cvLeftCol>div:last-of-type {
    padding-top: 40px;
}

.cvEntryDetails {
    display: flex;
    flex-wrap: wrap;
}

.cvEntryDetails strong {
    padding-right: 5px;
}

.longDesc {
    width: 100%;
}

.example-appear {
    opacity: 0.01;
}

.example-appear.example-appear-active {
    opacity: 1;
    transition: opacity .5s ease-in;
}

footer {
    text-align: center;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
    color: var(--footer-text-color);
    position: absolute;
    bottom: 0;
}

footer small,
footer small a {
    color: var(--footer-text-color);
    text-decoration: none;
    transition: all 0.2s;
}

footer small a:hover {
    color: var(--color-lightest);
}

.loading {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: appear 1.5s;
    animation-fill-mode: forwards;
}

.footNote {
    color: var(--text-color-muted);
    width: 100%;
    padding: 0 0 0 5px;
    display: block;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid var(--color-light);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.galleryComponent {
    padding: 20px 20px 90px 20px;
}

button {
    background-color: transparent;
    border: none;
    padding: 10px 0 10px 0;
    font-size: 26px;
}

button:hover {
    cursor: pointer;
}

button:focus,
button:active {
    border: none;
    outline: 0;
}

@media screen and (min-width: 950px) {
    .galleryComponent>div:first-of-type div {}
}

@media screen and (min-width: 1400px) {
    .galleryComponent>div:first-of-type div {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.modalImage {
    max-width: 100%;
}

@media screen and (max-width: 800px) {
    .personalInfo {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .personalInfo img {
        width: 200px;
        max-width: 100%;
        align-self: center;
    }

    .cvDate {
        margin-right: 10px;
        min-width: 67px;
    }

    .personalInfo>p:first-of-type {
        margin-bottom: 20px;
    }

    .container {
        padding-top: 20px;
    }

    .footNote {
        padding-left: 0;
    }

    .cv {
        flex-direction: column;
    }

    .cvEntryDetails p {
        width: 100%;
    }

    .cvRightCol,
    .cvLeftCol {
        width: 100%;
        padding: 0;
    }

    .personalInfo h2,
    .personalInfo>p:first-of-type {
        width: 100%;
        text-align: center;
    }

    .cvRightCol p:first-of-type {
        margin-top: 10px;
    }

    .cvEntry,
    .cvLeftCol>div:last-of-type {
        padding-top: 20px;
    }

    footer {
        font-size: 12px;
    }

    .cv {
        padding: 0 100px 90px 100px;
    }

    .iiz__btn {
        display: none;
    }

    .iiz {
        pointer-events: none;
    }
}

@media screen and (max-width: 600px) {
    .cv {
        padding: 0 20px 90px 20px;
    }
}

.textOnHover {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    height: 100%;
    box-sizing: border-box;
}

.textOnHover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
}

.textOnHover div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.74);
    transition: all 0.2s;
    opacity: 0;
    z-index: 1;
}

.textOnHover div h2 {
    text-align: center;
    padding: 10px;
}

.textOnHover:hover div {
    opacity: 1;
    cursor: pointer;
}

.textOnHover a {
    background-color: rgb(49, 49, 49);
    height: 100%;
    width: 100%;
}

.iiz {
    margin: 0;
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: zoom-in;
}

.iiz--drag .iiz__zoom-img--visible {
    cursor: grab;
}

.iiz__img {
    max-width: 100%;
    height: auto;
    display: block;
    pointer-events: none;
    visibility: visible;
    opacity: 1;
}

.iiz__img--invisible {
    visibility: hidden;
    opacity: 0;
}

.iiz__zoom-img {
    width: auto !important;
    max-width: none !important;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    display: block;
}

.iiz__zoom-img--visible {
    visibility: visible;
    opacity: 1;
    cursor: zoom-out;
}

.iiz__zoom-portal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
}

.iiz__btn {
    background: rgba(255, 255, 255, 0.8);
    width: 40px;
    height: 40px;
    border: none;
    outline: none;
    padding: 0;
    position: absolute;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-appearance: none;
    appearance: none;
}

.iiz__btn:before {
    content: " ";
    background-position: center;
    background-repeat: no-repeat;
    display: block;
}

.iiz__hint {
    bottom: 10px;
    right: 10px;
    pointer-events: none;
}

.iiz__hint:before {
    content: " ";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23000222'/%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
}

.iiz__close {
    top: 10px;
    right: 10px;
    visibility: hidden;
    opacity: 0;
}

.iiz__close--visible {
    visibility: visible;
    opacity: 1;
}

.iiz__close::before {
    content: " ";
    width: 29px;
    height: 29px;
    background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
    background-size: 100% 1px, 1px 100%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.modal>div>div:first-of-type:hover {
    cursor: pointer;
}

.modalData {
    display: flex;
    padding: 20px;
    margin-bottom: 70px;
}

.focusedContainer {
    position: relative;
    top: 10px;
    left: 0;
    margin-right: 20px;
    flex: 0 0 65%;
    width: 65%;
}

.focusedContainer figure {
    width: 100%;
}

.focusedContainer video {
    width: 100%;
}


.focused img {
    width: 100%;
}

.focused small {
    color: #999999;
    font-size: 12px;

}

.previews {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.previews>div {
    position: relative;
    width: calc(50% - 10px);
    margin: 5px;
    padding-bottom: calc(50% - 10px);
}

.previews img {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.previews img:hover {
    cursor: pointer;
}

.projectInfo {
    text-align: left;
    margin-right: -5px;
    margin-left: -5px;
}


.projectInfo p {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.projectInfo h2,
.projectInfo p {
    padding-left: 5px;
}


.navigateModal {
    padding: 0 5px;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    transform: translateY(-50%);
}

.navigateModal button {

    width: 31px;
    height: 30px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    border-radius: 50%;
    background-color: #ffffff;
    color: #252525;
    transition: all 0.2s;
}

.navigateModal button:disabled {
    opacity: 0.3;
}

.navigateModal button:hover {
    background-color: #FEDD95;
}

 .navigateModal {
    display: none;
}


@media screen and (max-width: 800px) {

    .navigateModal {
        padding: 0 5px;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        pointer-events: none;
        transform: translateY(-50%);
    }

    .focusedContainer { 
        width: 100%;
        padding-right: 0;
    }

    .modalData {
        flex-direction: column;
    }



    .projectInfo {
        width: 100%;
        margin-top: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    .previews {
        display: none;
    }

    .projectInfo h2,
    .projectInfo p,
    .footNote {
        padding-left: 0;
    }

}

  /* For WebKit-based browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
      width: 5px;
      /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
      background-color: 1a1b18;
      /* Track background */
  }

  ::-webkit-scrollbar-thumb {
      background-color: #1a1b18;
      /* Scrollbar thumb */
      border-radius: 16px;
      /* Rounded corners for the scrollbar thumb */
  }

  /* For Firefox */
  * {
      scrollbar-color: #515247 rgb(36, 39, 33);
      scrollbar-width: thin;
  }

  /* Optional: Hover effect */
  ::-webkit-scrollbar-thumb:hover {
      background-color: #363636;
      /* Hover effect for the thumb */
  }