.not-found-container {
    text-align: center;
    padding: 50px;
 
}

.not-found-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.not-found-container p {
    font-size: 1.2em;
} 